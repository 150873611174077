/* eslint-disable import/prefer-default-export */
/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    Card_mobile_image1: file(
      relativePath: { eq: "loodgieter/waterontharder.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Card_mobile_image2: file(
      relativePath: { eq: "loodgieter/waterontharder-2.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_mobile_image: file(
      relativePath: { eq: "loodgieter/loodgieter-6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ribbon_desktop_image: file(
      relativePath: { eq: "loodgieter/loodgieter-6.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Waterontharders`,
  inner: [
    `Waterontharders behandelen water om het ‘zachter’ te maken. Met aanzienlijk minder calcium, magnesium, ijzer en mangaan voorkomt u diverse problemen, zoals slijtage en problemen door kalk in huishoudelijke apparaten. Ook zijn waterontharders effectief bij sommige huidproblemen`,
  ],
};

const textBottom = {
  textWithLink: () => (
    <AniLink fade to='/loodgieterswerk' className='ml-sm-4x'>
      <Button
        role='button'
        variant='contained'
        className='btn btn--secondary heading__button'
      >
        <i className='material-icons mr-1x'>link</i>Terug naar overzicht
      </Button>
    </AniLink>
  ),
};

const Waterontharders = ({ data, path, location }) => {
  const sources = [
    data.Ribbon_mobile_image.childImageSharp.fluid,
    {
      ...data.Ribbon_desktop_image.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];
  const seo = {
    title: `Waterontharders`,
    description: `Uw waterontharder verlengt de levensduur van al deze apparaten zichtbaar. nder meer kranen, douches, vaatwassers, wasmachines en koffiezetters hebben veel te lijden onder kalkaanslag`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.Card_mobile_image1.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Wat doen waterontharders?',
    inner:
      'Met name calcium is een aanslag op apparaten die met water in aanraking komen. Onder meer kranen, douches, vaatwassers, wasmachines en koffiezetters hebben veel te lijden onder kalkaanslag. Ook de efficiency van verwarmingssystemen, ketels, boilers en geisers neemt af. Uw waterontharder verlengt de levensduur van al deze apparaten zichtbaar. ',
  };

  const CardHorizontal2 = {
    noSpace: true,
    textFirst: true,
    img: data.Card_mobile_image2.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Wat levert E S I',
    textWithLink: () => (
      <>
        <p>
          E S I levert en installeert diverse waterontharders en kan u daarmee
          per situatie adviseren en voorzien van de beste selectie passend bij
          uw wens.
        </p>
        <p>
          Meer informatie nodig? Bel ons gerust en wij helpen u graag verder
        </p>
        <div className='hidden-sm hidden-md hidden-lg'>
          <a href='tel:0642090304'>
            <Button
              role='button'
              variant='contained'
              className='btn btn--secondary heading__button mt-4x '
            >
              <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
            </Button>
          </a>
        </div>
        <AniLink fade to='/contact' className='hidden-xs'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </AniLink>
      </>
    ),
  };

  const ribbon = {
    image: sources,
    heading: `Voor al uw waterontharders`,
    pitchLine: `Verminder kalkaanslag, maak gebruik van een waterontharder`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        CardHorizontal2={CardHorizontal2}
        textBottom={textBottom}
      />
    </>
  );
};

Waterontharders.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Waterontharders;
